import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { User } from "../custom-types";

type UserState = {
  user: User | null;
  setUser: (user: User) => void;
  clearUser: () => void;
};

const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        setUser: (user: User) => set(() => ({ user })),
        clearUser: () => set(() => ({ user: null })),
      }),
      {
        name: "user",
      }
    )
  )
);

export default useUserStore;
