import { Outlet } from "react-router-dom";
import { useSidebar } from "../../hooks/useSidebar";
import { Header } from "./Header";
import { Protected } from "./Protected";
import { Sidebar } from "./Sidebar";

export const Layout = () => {
  const isOpen = useSidebar();

  return (
    <Protected>
      <Sidebar />
      <div className={`${isOpen ? "ml-80" : "ml-20"} relative duration-500`}>
        <Header />
        <div className="p-10 min-h-[calc(100vh_-_56px)]">
          <Outlet />
        </div>
      </div>
    </Protected>
  );
};
