import { Icon } from "@iconify/react";
import React from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { DetailsCard, DetailsCardItem } from "../../components/shared";
import { SitePlan } from "../../custom-types";
import useConfirm from "../../hooks/useConfirm";
import toast from "react-hot-toast";
import useMutate from "../../hooks/useMutation";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../utils/query-keys";

type SitePlanProps = {
  sitePlan: SitePlan | null;
  setSitePlan: React.Dispatch<React.SetStateAction<SitePlan | null>>;
};

export const SitePlanDetails: React.FC<SitePlanProps> = ({
  sitePlan = null,
  setSitePlan,
}) => {
  const queryClient = useQueryClient();
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();

  const { mutate } = useMutate();
  const handleDelete = async (sitePlan: SitePlan | null) => {
    const isConfirmed = await confirm({
      title: "Are You Sure?",
      message: `Are you sure you want to delete the site plan for ${sitePlan?.owner_information.first_name} ${sitePlan?.owner_information.last_name}`,
    });

    if (isConfirmed) {
      const toastId = toast.loading("Deleting site plan...");
      mutate(
        {
          url: `/siteplan/${sitePlan?._id}`,
          method: "DELETE",
        },
        {
          onSuccess(data) {
            queryClient.setQueryData<{ message: SitePlan[] }>(
              queryKeys.SitePlans.key,
              (oldData) => ({
                message: (oldData?.message ?? []).filter(
                  (item) => item._id !== sitePlan?._id
                ),
              })
            );
            toast.dismiss(toastId);
            toast.success("Site plan deleted successfully");
            setIsOpen(false);
            setSitePlan(null);
          },
          onError(error: any) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
            setIsOpen(false);
          },
        }
      );
    }
  };

  return (
    <DetailsCard
      heading="Site Plan Details"
      title={`${sitePlan?.owner_information.first_name} ${sitePlan?.owner_information.last_name}`}
      description={`${sitePlan?.owner_information.email}`}
      closeDetails={() => setSitePlan(null)}
      image={sitePlan?.qrcode_image}
      openDetails={!!sitePlan}
      editLink={`/site-plans/${sitePlan?._id}/edit`}
      actionButtons={() => (
        <button onClick={() => handleDelete(sitePlan)}>
          <Icon
            icon="fluent:delete-28-regular"
            className="text-xl text-red-500"
          />
        </button>
      )}
    >
      <div className="flex flex-col md:flex-row gap-5 p-5 rounded-md">
        <div className="bg-white rounded-md flex-1 md:w-60 md:min-h-60 mx-auto p-5">
          <img
            src={sitePlan?.scanned_site_plan}
            alt="qr-code"
            className="rounded-md w-full object-cover"
          />
        </div>
        <div className="bg-white rounded-md flex-1 p-5">
          {/* Owner's Information */}
          <div>
            <h3 className="font-bold mb-1">Owner's Information</h3>
            <div className="space-y-2 pl-2">
              <DetailsCardItem
                label="Full Name"
                value={`${sitePlan?.owner_information.first_name} ${sitePlan?.owner_information.middle_name} ${sitePlan?.owner_information.last_name}`}
              />
              <DetailsCardItem
                label="Email Address"
                value={sitePlan?.owner_information.email}
              />
              <DetailsCardItem
                label="Phone Number"
                value={formatPhoneNumberIntl(
                  sitePlan?.owner_information.main_phone_number ?? ""
                )}
              />
              <DetailsCardItem
                label="Alternate Phone Number"
                value={formatPhoneNumberIntl(
                  sitePlan?.owner_information.phone_numbers[0] ?? ""
                )}
              />
              <DetailsCardItem
                label="Postal Address"
                value={sitePlan?.owner_information.postal_address}
              />
              <DetailsCardItem
                label="GPS Address"
                value={sitePlan?.owner_information.gps_address}
              />
              <DetailsCardItem
                label="Identification Type"
                value={sitePlan?.owner_information.identification_type}
              />
              <DetailsCardItem
                label="Identification Code"
                value={sitePlan?.owner_information.identity_code}
              />
            </div>
          </div>

          {/* Plot Information */}
          <div>
            <h3 className="font-bold mt-5 mb-1">Plot Information</h3>
            <div className="space-y-2 pl-2">
              <DetailsCardItem
                label="Site Plan Hard Copy ID"
                value={sitePlan?.land_information.site_plan_hard_id}
              />
              <DetailsCardItem
                label="Locality"
                value={sitePlan?.land_information.locality}
              />
              <DetailsCardItem
                label="Municipality"
                value={sitePlan?.land_information.municipality}
              />

              <DetailsCardItem
                label="Region"
                value={sitePlan?.land_information.region}
              />
              <DetailsCardItem
                label="Block Number"
                value={sitePlan?.land_information.block_number}
              />
              <DetailsCardItem
                label="Sector"
                value={sitePlan?.land_information.sector}
              />
              <DetailsCardItem
                label="Plot Number"
                value={sitePlan?.land_information.plot_number}
              />
              <DetailsCardItem
                label="Total Land Area"
                value={sitePlan?.land_information.area}
              />
            </div>
          </div>

          <div>
            <h3 className="font-bold mt-5 mb-1">Surveyor Information</h3>
            <div className="space-y-2 pl-2">
              <DetailsCardItem
                label="Name"
                value={`${sitePlan?.land_information.surveyor.first_name} ${sitePlan?.land_information.surveyor.last_name}`}
              />
              <DetailsCardItem
                label="Email"
                value={sitePlan?.land_information.surveyor.email}
              />
              <DetailsCardItem
                label="Phone Number"
                value={formatPhoneNumberIntl(
                  sitePlan?.land_information.surveyor.phone_number ?? ""
                )}
              />
              <DetailsCardItem
                label="Ghana Card ID"
                value={sitePlan?.land_information.surveyor.ghana_card_id}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationDialog />
    </DetailsCard>
  );
};
