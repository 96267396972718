import { Icon } from "@iconify/react";
import React from "react";
import { useUser } from "../../hooks/useUser";
import { Avatar } from "../shared";

export const Header = () => {
  const user = useUser();

  return (
    <div
      className={`w-full h-14 shadow-md flex items-center px-5 md:px-10 justify-between`}
    >
      <div className="flex items-center gap-2">
        <Icon icon="lucide:search" />
        <input
          type="search"
          placeholder="Search in Dashboard..."
          className="p-2 outline-none"
        />
      </div>
      <div className="flex items-center gap-2">
        {user?.profile_image ? (
          <img src={user?.profile_image} alt="" />
        ) : (
          <Avatar />
        )}
        <p>
          {user?.first_name} {user?.last_name}
        </p>
      </div>
    </div>
  );
};
