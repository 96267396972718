import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

type SidebarState = {
  isOpen: boolean;
  setIsOpen: () => void;
};

const useSidebarStore = create<SidebarState>()(
  devtools(
    persist(
      (set) => ({
        isOpen: true,
        setIsOpen: () => set((state) => ({ isOpen: !state.isOpen })),
      }),
      {
        name: "sidebar",
      }
    )
  )
);

export default useSidebarStore;
