import { Icon } from "@iconify/react";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../../store/userStore";
import { useSidebar } from "../../hooks/useSidebar";
import useSidebarStore from "../../store/sidebarStore";

const menus = [
  { icon: "clarity:box-plot-line", label: "Site Plans", link: "/" },
  {
    icon: "gridicons:multiple-users",
    label: "Land Owners",
    link: "/land-owners",
  },
  {
    icon: "bi:qr-code-scan",
    label: "QR Code",
    link: "/qr-codes",
  },
  { icon: "bi:credit-card-2-back", label: "Payments", link: "/payments" },
  {
    icon: "icon-park-outline:land-surveying",
    label: "Surveyors",
    link: "/surveyors",
  },
  { icon: "ci:users-group", label: "Users", link: "/users" },
  { icon: "mdi:account-lock-outline", label: "Roles", link: "/roles" },
];

export const Sidebar = () => {
  const isOpen = useSidebar();
  const setIsOpen = useSidebarStore((state) => state.setIsOpen);
  const clearUser = useUserStore((state) => state.clearUser);
  const path = useLocation().pathname;
  const navigate = useNavigate();

  const logout = () => {
    clearUser();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      className={`fixed flex flex-col justify-between left-0 top-0 bg-black min-h-screen pt-8 pl-2 md:p-5 duration-300 ${
        isOpen ? "w-80" : "w-20"
      } z-50 duration-500`}
    >
      <button
        onClick={() => setIsOpen()}
        className="text-white absolute border border-black rounded-full top-2 p-1 cursor-pointer -right-5 flex justify-center items-center bg-white"
      >
        <Icon
          icon="ic:sharp-keyboard-arrow-right"
          className={`text-black text-3xl ${
            isOpen ? "rotate-180" : "rotate-0"
          } duration-500`}
        />
      </button>
      <div>
        <div className="flex items-center justify-center">
          <div className="flex justify-end mb-5">
            <img
              src="/images/logo.png"
              alt=""
              className={`w-32 h-32 ${
                isOpen
                  ? "translate-x-0 opacity-100 visible"
                  : "translate-x-40 opacity-0 invisible"
              } transform duration-500`}
            />
          </div>
          <img
            src="/images/logo.png"
            alt=""
            className={`h-10 ${
              isOpen
                ? "-translate-x-80 opacity-0 invisible"
                : "-translate-x-1 opacity-100 visible"
            } transform duration-500`}
          />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            {menus.map((menu, index) => (
              <Link
                key={index}
                to={menu.link}
                className={`flex my-1 text-white items-center gap-5 p-2 w-fit md:w-full rounded-md hover:bg-primary duration-300 ${
                  isOpen ? "pl-5" : "pl-3"
                } ${path === menu.link ? "bg-primary" : "bg-transparent"}`}
              >
                <Icon className="shrink-0" icon={menu.icon} />
                <span
                  className={`${
                    isOpen
                      ? "translate-x-0 opacity-100 visible"
                      : "translate-x-10 opacity-0 invisible"
                  } whitespace-nowrap transform duration-300`}
                  style={{ transitionDelay: `${index * 30}ms` }}
                >
                  {menu.label}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <button
        className={`flex my-1 text-white items-center gap-5 p-2 w-fit md:w-full rounded-md hover:bg-primary duration-300 ${
          isOpen ? "pl-5" : "pl-3"
        }`}
        onClick={logout}
      >
        <Icon className="shrink-0" icon="octicon:sign-out" />
        <span
          className={`${
            isOpen
              ? "translate-x-0 opacity-100 visible"
              : "translate-x-10 opacity-0 invisible"
          } whitespace-nowrap transform duration-300 delay-200`}
        >
          Logout
        </span>
      </button>
    </div>
  );
};
