import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Spinner, Table } from "../../components/shared";
import { Heading } from "../../components/shared/Heading";
import { QRCode } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";
import { useState } from "react";
import { QRCodeDetails } from "./QRCodeDetails";

export const AllQRCodes = () => {
  const [qrCode, setQrCode] = useState<QRCode | null>(null);
  const { data, isLoading } = useGetQuery<{ message: QRCode[] }>({
    queryKey: queryKeys.QRCodes.key,
    url: queryKeys.QRCodes.url,
  });

  const columnHelper = createColumnHelper<QRCode>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    columnHelper.accessor("_id", {
      header: "QR Code ID",
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor("site_plan.land_information.block_number", {
      header: "Land ID",
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor(
      (row) =>
        `${row.site_plan.owner_information.first_name} ${row.site_plan.owner_information.last_name}`,
      {
        header: "QR Code Owner",
        cell: (info) => info.getValue(),
      }
    ),
    columnHelper.accessor("qrcode", {
      header: "QR Code Picture",
      cell: (info) => <img src={info.getValue()} alt="" className="w-10" />,
    }),
    columnHelper.accessor("date_created", {
      header: "Date Created",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => setQrCode(info.row.original)}
          className="text-primary border border-primary hover:text-white hover:bg-primary duration-300 py-1 px-3 text-xs font-light rounded"
        >
          Details
        </button>
      ),
    }),
    // columnHelper.accessor((row) => `${row.gps_address}`, {
    //   header: "GPS Address",
    //   cell: (info) => info.getValue(),
    // }),
    // columnHelper.accessor((row) => `${row.date_created}`, {
    //   header: "Added Date",
    //   cell: (info) => info.getValue(),
    // }),
  ] as Array<ColumnDef<QRCode, unknown>>;

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <Heading text="All QR Codes" />
      <Table columns={columns} data={data?.message} />

      <QRCodeDetails qrCode={qrCode} setQrCode={setQrCode} />
    </div>
  );
};
