import React from "react";
import { useUser } from "../../hooks/useUser";
import { Navigate, useLocation } from "react-router-dom";

type ProtectedProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Protected: React.FC<ProtectedProps> = ({ children, ...props }) => {
  const user = useUser();
  const pathname = useLocation().pathname;

  if (user?.auth_token) {
    return <div {...props}>{children}</div>;
  } else {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
};
