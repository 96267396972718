import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import useUserStore from "../../store/userStore";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import toast from "react-hot-toast";
import useMutate from "../../hooks/useMutation";
import { InputField } from "../../components/shared";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  phone_number: z.string().length(10, "Please enter a valid phone number"),
  password: z.string().min(6, "Password must be at least 6 characters"),
});

type FormValues = z.infer<typeof schema>;

export const Login = () => {
  const user = useUser();
  const setUser = useUserStore((state) => state.setUser);
  const state = useLocation().state;

  const methods = useForm<FormValues>({
    defaultValues: {
      phone_number: "",
      password: "",
    },
    resolver: zodResolver(schema),
  });

  const { mutate } = useMutate();
  const navigate = useNavigate();
  const submit: SubmitHandler<FormValues> = (data) => {
    const toastId = toast.loading("Logging in...");

    mutate(
      {
        url: "/login",
        data,
      },
      {
        onSuccess(data) {
          console.log(data);
          setUser(data.message);
          toast.dismiss(toastId);
          toast.success("Login Successful");
          navigate(state?.from ? state?.from : "/");
        },
        onError(error: any) {
          toast.dismiss(toastId);
          toast.error(error.response.data.message.message);
        },
      }
    );
  };

  if (user?.auth_token) {
    return <Navigate to={state?.from ? state?.from : "/"} />;
  }

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="max-w-sm w-full border shadow-md p-5 rounded-2xl">
        <div className="flex flex-col items-center">
          <img src="/images/logo.png" alt="" className="w-32" />
          <h3 className="text-xl font-bold text-[#1B1C1C] mt-5">Sign In</h3>
          <p className="text-sm text-[#656767]">
            Please Sign in with your credentials
          </p>
        </div>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(submit)}
            className="space-y-3 mt-5"
          >
            <InputField
              name="phone_number"
              label="Phone Number"
              labelColor="text-primary"
            />
            <div>
              <InputField
                type="password"
                name="password"
                label="Password"
                labelColor="text-primary"
              />
              <div className="flex justify-end mt-2">
                <p className="text-primary text-sm font-semibold">
                  Recover Password
                </p>
              </div>
            </div>
            <div className="flex justify-center">
              <button className="bg-primary text-white py-2 px-10 rounded-md">
                Sign In
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
