import { useParams } from "react-router-dom";
import { SurveyorForm } from "../../components/forms";
import { Spinner } from "../../components/shared";
import { Surveyor } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";

const EditSurveyor = () => {
  const { surveyorId } = useParams();
  console.log(surveyorId);
  const { data, isLoading } = useGetQuery<{ message: Surveyor }>({
    url: `/surveyor/${surveyorId}`,
    queryKey: queryKeys.Surveyor.key(surveyorId as string),
  });

  console.log(data);

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <SurveyorForm surveyor={data?.message} />
    </div>
  );
};

export default EditSurveyor;
