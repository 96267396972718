import React from "react";
import { ReactQueryProvider } from "./react-query";

type ProvidersProps = {
  children: React.ReactNode;
};

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return <ReactQueryProvider>{children}</ReactQueryProvider>;
};
