import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { Permission, Role } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import useMutate from "../../hooks/useMutation";
import { Chip, ErrorMessage, InputField } from "../shared";

const schema = z.object({
  name: z.string().min(1, "Role name is required"),
  permissions: z.string().array().min(1, "At lease one permission is required"),
});

type FormValues = z.infer<typeof schema>;

type RoleFormProps = {
  role: Role | null;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RoleForm: React.FC<RoleFormProps> = ({ role, setOpenModal }) => {
  const queryClient = useQueryClient();
  const methods = useForm<FormValues>({
    defaultValues: {
      name: role?.name || "",
      permissions: [],
    },
    resolver: zodResolver(schema),
  });
  const [permissions, setPermissions] = useState<string[]>([]);

  const { data, isLoading: loadingPermission } = useGetQuery<Permission[]>({
    queryKey: ["permissions"],
    url: "/permissions",
  });

  const handleChange = (incomingPermission: string) => {
    if (permissions.includes(incomingPermission)) {
      setPermissions(
        permissions.filter((permission) => permission !== incomingPermission)
      );
    } else {
      setPermissions([...permissions, incomingPermission]);
    }
  };

  const removePermission = (permissionToRemove: string) =>
    setPermissions(
      permissions.filter((permission) => permission !== permissionToRemove)
    );

  const { mutate } = useMutate([]);
  const submitHandler: SubmitHandler<FormValues> = async (data) => {
    const toastId = toast.loading(`${role ? "Creating" : "Updating"} role...`);

    console.log(data);

    mutate(
      {
        url: role ? `/role` : "/add/role",
        data: {
          ...data,
          ...(role && { role_id: role._id }),
        },
        method: role ? "PATCH" : "POST",
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData<Role[]>(["roles"], (oldData) => {
            if (role) {
              return (oldData ?? []).map((item) => {
                if (item._id === data.role_id) {
                  return data;
                }
                return item;
              });
            } else {
              return [data, ...(oldData ?? [])];
            }
          });
          toast.dismiss(toastId);
          toast.success(`Role ${role ? "updated" : "created"} successfully`);
          setOpenModal(false);
        },
        onError: (error: any) => {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
          setOpenModal(false);
        },
      }
    );
  };

  useEffect(() => {
    if (role) {
      setPermissions(role.permissions);
    }
  }, [role]);

  useEffect(() => {
    methods.setValue("permissions", permissions);
  }, [permissions, methods]);

  return (
    <div className="cursor-pointer p-5">
      <div className="text-black text-xl font-semibold font-kas mb-2">
        <h3>Add Role & Permission</h3>
      </div>

      <div className=" text-neutral-400 text-sm font-kas font-normal mt-2 mb-6">
        Add permissions to create role below.
      </div>

      <div className=" bg-white rounded-lg">
        <div className="min-h-fit space-y-10 flex flex-col justify-center items-center p-">
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(submitHandler)}
              className="w-full"
            >
              <div className="space-y-5">
                <div className="mb-5">
                  <label className=" text-black text-md font-semibold leading-loose">
                    Role Name
                  </label>
                  <InputField name="name" required />
                </div>
                <div className="flex gap-3 flex-wrap">
                  {permissions.map((permission, index) => (
                    <Chip
                      key={index}
                      label={permission}
                      onClick={() => removePermission(permission)}
                    />
                  ))}
                </div>
                <div>
                  <label className="text-black text-md font-semibold leading-loose ">
                    System Module Permission:
                  </label>
                  {loadingPermission ? (
                    <div className="text-center text-xl flex">
                      <span>Loading</span>
                      <Icon
                        icon="eos-icons:three-dots-loading"
                        className="text-3xl"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-5 mt-2">
                      {data?.map((module, index) => (
                        <label
                          key={index}
                          className="space-x-2 basis-48 flex-1 block text-md font-normal text-slate-700 cursor-pointer "
                        >
                          <input
                            type="checkbox"
                            id="permissions"
                            name="permissions"
                            className="accent-primary"
                            onChange={() => handleChange(module.name)}
                            checked={permissions.includes(module.name)}
                          />
                          <span>{module.name}</span>
                        </label>
                      ))}
                    </div>
                  )}
                  <ErrorMessage name="permissions" />
                </div>
              </div>
              <div className="flex justify-end">
                <button className="px-6 py-1 w-48 bg-primary font-kas rounded-lg shadow justify-center items-center gap-2.5 flex mt-6 text-white text-md font-bold leading-loose ">
                  Add role
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default RoleForm;
