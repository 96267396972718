export const queryKeys = {
  Users: {
    key: ["users"],
    url: "/users",
  },
  User: {
    key: (id: string) => ["users", id],
    url: (id: string) => `/users/${id}`,
  },

  SitePlans: {
    key: ["site-plans"],
    url: "/siteplans",
  },
  SitePlan: {
    key: (id: string) => ["site-plans", id],
    url: (id: string) => `/siteplan/${id}`,
  },

  LandOwners: {
    key: ["land-owners"],
    url: "/landowners",
  },
  LandOwner: {
    key: (id: string) => ["land-owners", id],
    url: (id: string) => `/landowners/${id}`,
  },

  QRCodes: {
    key: ["qr-codes"],
    url: "/qrcodes",
  },
  QRCode: {
    key: (id: string) => ["qr-codes", id],
    url: (id: string) => `/qrcode/${id}`,
  },
  Payments: {
    key: ["payments"],
    url: "/payments",
  },
  Roles: {
    key: ["roles"],
    url: "/roles",
  },
  Permissions: {
    key: ["permissions"],
    url: "/permissions",
  },
  Surveyors: {
    key: ["surveyors"],
    url: "/surveyors",
  },
  Surveyor: {
    key: (id: string) => ["surveyors", id],
    url: (id: string) => `/surveyor/${id}`,
  },
};
