import { Toaster } from "react-hot-toast";
import { Router } from "./components/router";
import { Providers } from "./components/providers";
import "react-phone-number-input/style.css";

function App() {
  return (
    <Providers>
      <Router />
      <Toaster />
    </Providers>
  );
}

export default App;
