import { Icon } from "@iconify/react";
import React from "react";
import useClickedOutside from "../../hooks/useClickedOutside";

type ModalProps = {
  openModal: boolean;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  width?: string;
  className?: string;
  fullWidth?: boolean;
  start?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  openModal,
  closeModal,
  children,
  width = "max-w-xl",
  className = "",
  fullWidth = false,
  start = false,
}) => {
  const modalRef = useClickedOutside(() => closeModal(false));

  return (
    <>
      {openModal ? (
        <div
          className={`min-h-screen fixed top-0 right-0 bottom-0 ${
            fullWidth ? "left-0" : "left-80"
          } h-screen grid grid-cols-1 ${
            start ? "place-content-start" : "place-content-center"
          } p-10 overflow-y-auto  bg-neutral-700/30 z-40 ${className}`}
        >
          <div
            ref={modalRef}
            className={`relative p-5 bg-white rounded-xl w-full mx-auto ${width}`}
          >
            <Icon
              onClick={() => closeModal(false)}
              icon="line-md:close-circle-twotone"
              className="absolute -right-4 -top-4 text-4xl text-primary cursor-pointer"
            />
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};
