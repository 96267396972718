import React from "react";
import { SitePlanForm } from "../../components/forms";

export const AddSitePlan = () => {
  return (
    <div>
      <SitePlanForm />
    </div>
  );
};
