import React, { useState } from "react";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";
import { Owner } from "../../custom-types";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Heading } from "../../components/shared/Heading";
import { Spinner, Table } from "../../components/shared";
import { LandOwnerDetails } from "./LandOwnerDetails";

export const AllLandOwners = () => {
  const [landOwner, setLandOwner] = useState<Owner | null>(null);
  const { data, isLoading } = useGetQuery<{ message: Owner[] }>({
    queryKey: queryKeys.LandOwners.key,
    url: queryKeys.LandOwners.url,
  });

  const columnHelper = createColumnHelper<Owner>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
      id: "Owner's Name",
      cell: (props) => (
        <div className="flex items-center">
          <div className="ml-2">
            <div className=" text-blue-900 text-[15px] font-bold leading-snug">
              {props.row.original.first_name} {props.row.original.last_name}
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => `${row.email}`, {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => `${row.main_phone_number}`, {
      header: "Owner's Number",
      cell: (info) => (
        <span>{formatPhoneNumberIntl(info.getValue() ?? "")}</span>
      ),
    }),
    columnHelper.accessor((row) => `${row.gps_address}`, {
      header: "GPS Address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => `${row.date_created}`, {
      header: "Added Date",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => setLandOwner(info.row.original)}
          className="text-primary border border-primary hover:text-white hover:bg-primary duration-300 py-1 px-3 text-xs font-light rounded"
        >
          Details
        </button>
      ),
    }),
  ] as Array<ColumnDef<Owner, unknown>>;

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <Heading text="All Land Owners" />
      <Table columns={columns} data={data?.message} />

      <LandOwnerDetails landOwner={landOwner} setLandOwner={setLandOwner} />
    </div>
  );
};
