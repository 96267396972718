import React from "react";
import { useFormContext } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { Label } from "./Label";
import { Row } from "./Row";
import { ErrorMessage } from "./ErrorMessage";

type PhoneInputProps = {
  label: string;
  name: string;
  required?: boolean;
};

export const PhoneInput: React.FC<PhoneInputProps> = ({
  label,
  name,
  required = true,
}) => {
  const { control } = useFormContext();

  return (
    <Row>
      <Label text={label} />
      <PhoneInputWithCountry
        international
        defaultCountry="GH"
        name={name}
        control={control}
        className="placeholder:text-slate-400 bg-white w-full outline-none border border-slate-400 shadow-md rounded-md p-3 sm:text-sm"
        rules={{ required }}
      />
      <ErrorMessage name={name} />
    </Row>
  );
};
