import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Spinner, Table } from "../../components/shared";
import { Heading } from "../../components/shared/Heading";
import { Payment } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";

export const AllPayments = () => {
  const { data, isLoading } = useGetQuery<{ message: Payment[] }>({
    queryKey: queryKeys.Payments.key,
    url: queryKeys.Payments.url,
    method: "POST",
    data: {
      purpose: "land_site_plan_payment",
    },
  });

  console.log({ data });

  const columnHelper = createColumnHelper<Payment>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    columnHelper.accessor("transactionId", {
      header: "Transaction ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("phone_number", {
      header: "Phone Number",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("currency", {
      header: "Currency",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("network", {
      header: "Network",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => info.getValue(),
    }),
  ] as Array<ColumnDef<Payment, unknown>>;

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <Heading text="All Payments" />
      <Table columns={columns} data={data?.message} />
    </div>
  );
};
