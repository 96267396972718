import { Icon } from "@iconify/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { UserForm } from "../../components/forms";
import { Modal, Table } from "../../components/shared";
import { Heading } from "../../components/shared/Heading";
import { Employee } from "../../custom-types";

export const AllUsers = () => {
  const [user, setUser] = useState<Employee | null>(null);
  const [openModal, setOpenModal] = useState(false);
  // const { data, isLoading } = useGetQuery<{ message: Employee[] }>({
  //   queryKey: queryKeys.Users.key,
  //   url: queryKeys.Users.url,
  // });

  const columnHelper = createColumnHelper<Employee>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    // columnHelper.accessor(
    //   (row) =>
    //     `${row.owner_information.first_name} ${row.owner_information.last_name}`,
    //   {
    //     id: "Owner's Name",
    //     cell: (props) => (
    //       <div className="flex items-center">
    //         <div>
    //           {props.row.original.land_information.current_owner
    //             .profile_image ? (
    //             <img
    //               src={
    //                 props.row.original.land_information.current_owner
    //                   .profile_image
    //               }
    //               alt=""
    //               className="h-12 w-12 object-cover rounded-full"
    //             />
    //           ) : (
    //             <Avatar size={40} />
    //           )}
    //         </div>
    //         <div className="ml-2">
    //           <div className=" text-blue-900 text-[15px] font-bold leading-snug">
    //             {props.row.original.owner_information.first_name}{" "}
    //             {props.row.original.owner_information.last_name}
    //           </div>
    //         </div>
    //       </div>
    //     ),
    //   }
    // ),
    columnHelper.display({
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => setUser(info.row.original)}
          className="text-primary border border-primary hover:text-white hover:bg-primary duration-300 py-1 px-3 text-xs font-light rounded"
        >
          Details
        </button>
      ),
    }),
  ] as Array<ColumnDef<Employee, unknown>>;

  return (
    <div>
      {/* <Spinner isLoading={isLoading} /> */}
      <div className="flex items-center justify-between">
        <Heading text="All Users" />
        <button
          onClick={() => setOpenModal(true)}
          className="text-xs bg-primary py-2 rounded-md px-3 text-white font-normal flex items-center gap-2"
        >
          <Icon
            icon="material-symbols-light:add-circle-outline"
            fontSize={18}
          />
          New User
        </button>
      </div>
      <Table columns={columns} data={[]} />

      <Modal start openModal={openModal} closeModal={setOpenModal}>
        <UserForm user={user} closeModal={setOpenModal} />
      </Modal>
    </div>
  );
};
