import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

type InputFieldProps = {
  name: string;
  label?: string;
  labelColor?: string;
  required?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label = "",
  labelColor = "text-blue-900",
  required = true,
  type = "text",
  ...res
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex-1">
      {label && (
        <label
          className={`mb-1 block text-md font-semibold leading-loose ${labelColor}`}
        >
          {label}{" "}
          {!required && (
            <span className="text-slate-300 text-md">(Optional)</span>
          )}
        </label>
      )}
      <div className="flex items-center bg-white w-full outline-none border pr-3 border-slate-400 shadow-md rounded-md">
        <input
          type={showPassword ? "text" : type}
          className=" placeholder:text-slate-400 block w-full outline-none py-3 pl-3 pr-3 sm:text-sm"
          {...register(name)}
          {...res}
        />
        {type === "password" ? (
          <Icon
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? "iconoir:eye" : "iconoir:eye-closed"}
            className="text-2xl leading-[40] cursor-pointer"
          />
        ) : null}
      </div>
      {errors[name] && (
        <span className="text-red-500 text-[12px] pl-1">
          {errors[name]?.message as string}
        </span>
      )}
    </div>
  );
};
