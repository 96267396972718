import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SitePlan, Surveyor } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import useMutate from "../../hooks/useMutation";
import {
  CustomFileInput,
  CustomSelect,
  ErrorMessage,
  FormRow,
  InputField,
  Label,
  PhoneInput,
  Row,
} from "../shared";
import { Heading } from "../shared/Heading";

const schema = z
  .object({
    first_name: z.string().min(1, "First Name is required"),
    last_name: z.string().min(1, "Last Name is required"),
    middle_name: z.string().optional(),
    email: z.string().email(),
    main_phone_number: z.string().min(5, "Phone number is required"),
    phone_numbers: z.string().min(5, "Alternate phone number is required"),
    postal_address: z.string().optional(),
    gps_address: z.string().optional(),
    locality: z.string().min(1, "Please provide the locality"),
    region: z.string().min(1, "Please provide the locality"),
    municipality: z.string().min(1, "Please provide the locality"),
    block_number: z.string().min(1, "Block number of plot is required"),
    sector: z.string().min(1, "Please provide the sector"),
    plot_number: z.string().min(1, "Plot number is required"),
    identification_type: z.string(),
    identity_code: z.string().optional(),
    surveyor: z.string().min(1, "Please kindly select a surveyor"),
    area: z.string().min(1, "Total land area plot is required"),
    scanned_site_plan: z.any(),
  })
  .superRefine((val, ctx) => {
    let ghanaCard = /^GHA-\d{9}-\d$/i;
    let passport = /^[GH]\d{7}$/i;

    if (val.identification_type && !val?.identity_code) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter card number`,
        path: ["identity_code"],
      });
    } else if (val?.identity_code && !val.identification_type) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please select card type`,
        path: ["identification_type"],
      });
    } else if (
      val.identification_type === "Ghana Card" &&
      val.identity_code &&
      !ghanaCard.test(val.identity_code)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid Ghana Card number. eg. GHA-123456789-0`,
        path: ["identity_code"],
      });
    } else if (
      val.identification_type === "Passport" &&
      val.identity_code &&
      !passport.test(val.identity_code)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid Passport number. eg. G1234567`,
        path: ["identity_code"],
      });
    } else if (
      val.identification_type === "Voters ID" &&
      val.identity_code &&
      val.identity_code.length !== 10
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid Voters ID number`,
        path: ["identity_code"],
      });
    }

    if (
      val.main_phone_number.startsWith("+233") &&
      val.main_phone_number.length !== 13
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid phone number`,
        path: ["main_phone_number"],
      });
    } else if (!isPossiblePhoneNumber(val.main_phone_number)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid phone number`,
        path: ["main_phone_number"],
      });
    }

    if (
      val.phone_numbers.startsWith("+233") &&
      val.phone_numbers.length !== 13
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid phone number`,
        path: ["phone_numbers"],
      });
    } else if (!isPossiblePhoneNumber(val.phone_numbers)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please enter a valid phone number`,
        path: ["phone_numbers"],
      });
    }
  });

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  email: "",
  main_phone_number: "",
  phone_numbers: "",
  postal_address: "",
  gps_address: "",
  identity_code: "",
  identification_type: "",
  locality: "",
  municipality: "",
  region: "",
  block_number: "",
  sector: "",
  plot_number: "",
  area: "",
  scanned_site_plan: "",
  surveyor: "",
};

const regions = [
  "Ahafo",
  "Ashanti",
  "Bono",
  "Bono East",
  "Central",
  "Eastern",
  "Greater Accra",
  "North East",
  "Northern",
  "Oti",
  "Savannah",
  "Upper East",
  "Upper West",
  "Western",
  "Western North",
  "Volta",
];

const identifications = [
  "Ghana Card",
  "Passport",
  "Voters ID",
  "Health Insurance",
];

type SitePlanFormProps = {
  sitePlan?: SitePlan;
};

export const SitePlanForm: React.FC<SitePlanFormProps> = ({ sitePlan }) => {
  const [image, setImage] = useState<File[] | null>(null);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [surveyorId, setSurveyorId] = useState("");
  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { data, isLoading } = useGetQuery<{ message: Surveyor[] }>({
    queryKey: ["surveyors"],
    url: "/surveyors",
  });

  const surveyors = data?.message.map((surveyor) => ({
    id: surveyor?._id,
    label: `${surveyor?.first_name} ${surveyor?.last_name}`,
  }));

  const navigate = useNavigate();
  const { mutate } = useMutate();
  const submit: SubmitHandler<FormValues> = (data) => {
    const toastId = toast.loading("Adding new site plan");
    const formData = new FormData();
    Object.entries(data).forEach((item) => {
      formData.append(...item);
    });

    console.log(data);

    mutate(
      {
        url: sitePlan ? `/siteplan/${sitePlan?._id}` : "/add/siteplan",
        data: formData,
        method: sitePlan ? "PATCH" : "POST",
        multipart: true,
      },
      {
        onSuccess(data) {
          // queryClient.setQueryData<{ message: SitePlan[] }>(
          //   queryKeys.SitePlans.key,
          //   (oldData) =>
          //     sitePlan
          //       ? {
          //           message: (oldData?.message ?? []).map((item) =>
          //             item._id === sitePlan._id ? data.message : item
          //           ),
          //         }
          //       : {
          //           message: [data.message, ...(oldData?.message ?? [])],
          //         }
          // );
          toast.dismiss(toastId);
          toast.success(
            `Siteplan ${sitePlan ? "added" : "updated"} successfully`
          );
          navigate("/");
        },
        onError(error: any) {
          toast.dismiss(toastId);
          toast.error(error.response.data.message.message);
        },
      }
    );
  };

  useEffect(() => {
    if (image) {
      methods.setValue("scanned_site_plan", image[0]);
      setPreviewImage(URL.createObjectURL(image?.[0]));
    }
  }, [image, methods]);

  useEffect(() => {
    if (surveyorId) {
      methods.setValue("surveyor", surveyorId);
      methods.clearErrors("surveyor");
    }
  }, [surveyorId, methods]);

  useEffect(() => {
    if (sitePlan) {
      const { owner_information, land_information } = sitePlan;
      const { setValue } = methods;

      setValue("first_name", owner_information.first_name);
      setValue("last_name", owner_information.last_name);
      setValue("middle_name", owner_information.middle_name);
      setValue("email", owner_information.email);
      setValue("main_phone_number", owner_information.main_phone_number);
      setValue("phone_numbers", owner_information.phone_numbers[0]);
      setValue("postal_address", owner_information.postal_address);
      setValue("gps_address", owner_information.gps_address);
      setValue("identification_type", owner_information.identification_type);
      setValue("identity_code", owner_information.identity_code);

      setValue("locality", land_information.locality);
      setValue("municipality", land_information.municipality);
      setValue("region", land_information.region);
      setValue("plot_number", land_information.plot_number);
      setValue("block_number", land_information.block_number);
      setValue("sector", land_information.sector);
      setValue("area", land_information.area);
      setValue("surveyor", land_information.surveyor._id);
    }
  }, [sitePlan, methods]);

  return (
    <div>
      <Heading text="Add Site Plan" />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(submit)}
          className="w-full space-y-10 mt-5"
        >
          <fieldset className="border border-gray-500 p-5 rounded-md">
            <legend>Owner's Information</legend>
            <FormRow>
              <InputField name="first_name" label="First Name" />
              <InputField name="last_name" label="Last Name" />
            </FormRow>
            <FormRow>
              <InputField
                name="middle_name"
                label="Middle Name"
                required={false}
              />
              <InputField name="email" type="email" label="Email" />
            </FormRow>
            <FormRow>
              <PhoneInput name="main_phone_number" label="Phone Number" />
              <PhoneInput name="phone_numbers" label="Alternate Phone Number" />
            </FormRow>
            <FormRow>
              <InputField name="postal_address" label="Postal Address" />
              <InputField name="gps_address" label="GPS Address" />
            </FormRow>
            <FormRow>
              <div className="flex-1">
                <Label text="Identification Cards" />
                <select
                  {...methods.register("identification_type")}
                  className="placeholder:text-slate-400 block bg-white w-full outline-none border border-slate-400 shadow-md rounded-md py-3 pl-9 pr-3 sm:text-sm"
                >
                  <option value="">Select Card Type</option>
                  {identifications.map((identification, idx) => (
                    <option key={idx} value={identification}>
                      {identification}
                    </option>
                  ))}
                </select>
                <ErrorMessage name="identity_type" />
              </div>
              <InputField name="identity_code" label="Identity Code" />
            </FormRow>
          </fieldset>

          <fieldset className="border border-gray-500 p-5 rounded-md">
            <legend>Plot Information</legend>

            <FormRow>
              <Row>
                <CustomSelect
                  data={surveyors}
                  loading={isLoading}
                  initialValue={sitePlan?.land_information.surveyor._id}
                  onChange={setSurveyorId}
                  label="Surveyor"
                />
              </Row>
              <InputField name="plot_number" label="Plot Number" />
            </FormRow>

            <FormRow>
              <InputField name="block_number" label="Block Number" />
              <InputField name="sector" label="Sector" />
            </FormRow>
            <FormRow>
              <InputField name="area" label="Total Land Area" />
              <InputField name="locality" label="Locality" />
            </FormRow>

            <FormRow>
              <InputField name="municipality" label="Municipality" />
              <div className="flex-1">
                <Label text="Region" />
                <select
                  {...methods.register("region")}
                  className="placeholder:text-slate-400 block bg-white w-full outline-none border border-slate-400 shadow-md rounded-md py-3 pl-9 pr-3 sm:text-sm"
                >
                  <option value="">Select Region</option>
                  {regions.map((region, idx) => (
                    <option key={idx} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </div>
            </FormRow>

            <div className="flex flex-col gap-5 items-center md:flex-row md:items-end max-w-5xl md:justify-center mx-auto">
              {sitePlan ? (
                <div className="overflow-hidden flex justify-center">
                  <img
                    src={sitePlan.scanned_site_plan}
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="w-32 h-32 rounded object-cover"
                  />
                </div>
              ) : null}
              {!sitePlan && previewImage ? (
                <div className="overflow-hidden flex justify-center">
                  <img
                    src={previewImage}
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="w-32 h-32 rounded object-cover"
                  />
                </div>
              ) : null}
              <div className="flex items-center gap-5 mt-5">
                {sitePlan && previewImage ? (
                  <div className="overflow-hidden flex justify-center basis-40">
                    <img
                      src={previewImage}
                      style={{ objectFit: "cover" }}
                      alt=""
                      className="w-32 h-32 rounded object-cover"
                    />
                  </div>
                ) : null}
                <div className="flex-1">
                  <CustomFileInput onChange={setImage} height="h-32" required />
                </div>
              </div>
            </div>
          </fieldset>

          <div className="flex justify-end">
            <button className="bg-primary text-white py-2 px-5 rounded-md float-right">
              Add Site Plan
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
