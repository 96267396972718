import React from "react";

type LabelProps = {
  text: string;
  required?: boolean;
} & React.HTMLAttributes<HTMLLabelElement>;

export const Label: React.FC<LabelProps> = ({ text, required = true }) => {
  return (
    <label className="mb-1 block text-blue-900 text-md font-semibold leading-loose">
      {text}{" "}
      {!required && <span className="text-slate-300 text-md">(Optional)</span>}
    </label>
  );
};
