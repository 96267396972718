import { Icon } from "@iconify/react";
import React from "react";

type AvatarProps = {
  size?: number;
};

export const Avatar: React.FC<AvatarProps> = ({ size = 30 }) => {
  return (
    <Icon
      icon="material-symbols:account-circle"
      className="text-gray-700"
      fontSize={size}
    />
  );
};
