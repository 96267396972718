import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { Employee, Role, User } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import useMutate from "../../hooks/useMutation";
import { queryKeys } from "../../utils/query-keys";
import { CustomSelect, ErrorMessage, InputField } from "../shared";

const schema = (user: Employee | null) =>
  z.object({
    first_name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    phone_number: z.string().length(10, "Please enter a valid phone number"),
    email: z.string().email(),
    profile_image: z.any(),
    ...(!user && {
      password: z.string().min(6, "Password must be at least 6 characters"),
    }),
    role_id: z.string().min(1, "Please kindly select role"),
  });

type UserFormProps = {
  user: Employee | null;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserForm: React.FC<UserFormProps> = ({ user, closeModal }) => {
  const queryClient = useQueryClient();
  const [roleId, setRoleId] = useState<string>("");
  const schemaType = schema(user);
  type FormValues = z.infer<typeof schemaType>;
  const methods = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      profile_image: "",
      phone_number: "",
      role_id: "",
    },
    resolver: zodResolver(schema(user)),
  });

  const { data, isLoading } = useGetQuery<Role[]>({
    url: "/roles",
    queryKey: queryKeys.Roles.key,
  });

  const { mutate } = useMutate();
  const submit: SubmitHandler<FormValues> = (data) => {
    const toastId = toast.loading(
      `${user ? "Updating" : "Adding new"} User...`
    );
    const formData = new FormData();
    Object.entries(data).forEach((item) => {
      formData.append(...item);
    });

    console.log(data);

    mutate(
      {
        url: user ? `/user/${user?._id}` : "/register",
        method: user ? "PATCH" : "POST",
        data,
      },
      {
        onSuccess(data) {
          if (data.message.message.includes("already exist")) {
            toast.dismiss(toastId);
            toast.error(data.message.message);
          } else {
            queryClient.setQueryData<{ message: User[] }>(
              queryKeys.Users.key,
              (oldData) => {
                if (user) {
                  return {
                    message: (oldData?.message ?? []).map((item) => {
                      if (item._id === user._id) {
                        return data.message;
                      } else {
                        return item;
                      }
                    }),
                  };
                } else {
                  return {
                    message: [data.message, ...(oldData?.message ?? [])],
                  };
                }
              }
            );
            toast.dismiss(toastId);
            toast.success(
              `User ${user ? "info updated" : "added"} successfully`
            );
            closeModal(false);
          }
        },
        onError(error: any) {
          console.log(error);
          toast.dismiss(toastId);
          toast.error(error.response.data.message.message);
        },
      }
    );
  };

  const roleData = data?.map((item) => ({
    id: item._id,
    label: item.name,
  }));

  useEffect(() => {
    if (roleId) {
      methods.setValue("role_id", roleId);
    }
  }, [roleId, methods]);

  console.log(roleId);

  console.log(methods.getValues());

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <div className="text-zinc-900 text-2xl font-semibold font-kas leading-loose">
            Add User
          </div>
          <div className="text-neutral-500 text-sm font-normal font-kas leading-[21px]">
            These will only take you few minutes
          </div>

          <div className="mt-7 space-y-3">
            <div className="flex justify-between gap-3">
              <InputField
                name="first_name"
                label="First Name"
                placeholder="Enter Your First Name"
                required
              />

              <InputField
                name="last_name"
                label="Last Name"
                placeholder="Enter Your Last Name"
                required
              />
            </div>

            <InputField
              name="email"
              label="Email"
              placeholder="Enter Your Email"
            />

            <InputField
              name="phone_number"
              label="Phone Number"
              placeholder="Enter Phone Number"
              required
            />

            {!user ? (
              <div className="flex items-end gap-2">
                <InputField
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Create Password"
                  required
                />
              </div>
            ) : null}

            <CustomSelect
              label="Select Role"
              placeholder="Select User's Role"
              loading={isLoading}
              data={roleData}
              initialValue={user?.role._id}
              onChange={setRoleId}
            />
            <ErrorMessage name="role_id" />
          </div>

          <div className="mt-6 flex justify-between gap-5">
            <button
              type="button"
              onClick={() => closeModal(false)}
              className="flex-1 rounded-xl font-kas text-sm border"
            >
              Cancel
            </button>
            <button className="flex-1 rounded-xl font-kas text-sm bg-primary text-white py-2">
              {user ? "Update" : "Add"} User
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
