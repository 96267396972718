import { useParams } from "react-router-dom";
import { SitePlanForm } from "../../components/forms";
import { Spinner } from "../../components/shared";
import { SitePlan } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";

export const EditSitePlan = () => {
  const { sitePlanId } = useParams();
  const { data, isLoading } = useGetQuery<{ message: SitePlan }>({
    queryKey: queryKeys.SitePlan.key(sitePlanId as string),
    url: queryKeys.SitePlan.url(sitePlanId as string),
  });

  console.log({ data });

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <SitePlanForm sitePlan={data?.message} />
    </div>
  );
};
