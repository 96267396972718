import React from "react";
import { Owner } from "../../custom-types";
import { DetailsCard, DetailsCardItem } from "../../components/shared";

type LandOwnerDetailsProps = {
  landOwner: Owner | null;
  setLandOwner: React.Dispatch<React.SetStateAction<Owner | null>>;
};

export const LandOwnerDetails: React.FC<LandOwnerDetailsProps> = ({
  landOwner,
  setLandOwner,
}) => {
  return (
    <DetailsCard
      heading="Land Owner Details"
      title={`${landOwner?.first_name} ${landOwner?.last_name}`}
      description={`${landOwner?.email}`}
      openDetails={!!landOwner}
      closeDetails={() => setLandOwner(null)}
      image={landOwner?.profile_image}
      avatar
    >
      <div className="bg-white rounded-xl p-10 space-y-3">
        <DetailsCardItem
          label="Full Name"
          value={`${landOwner?.first_name} ${landOwner?.middle_name} ${landOwner?.last_name}`}
        />
        <DetailsCardItem label="Email" value={landOwner?.email} />
        <DetailsCardItem
          label="Phone Number"
          value={landOwner?.main_phone_number}
        />
        <DetailsCardItem
          label="Alternate Phone Number"
          value={landOwner?.phone_numbers}
        />
        <DetailsCardItem
          label="Postal Address"
          value={landOwner?.postal_address}
        />
        <DetailsCardItem label="GPS Address" value={landOwner?.gps_address} />
        <DetailsCardItem
          label="Identification Type"
          value={landOwner?.identification_type}
        />
        <DetailsCardItem
          label="Identification Code"
          value={landOwner?.identity_code}
        />
        <DetailsCardItem label="Date Added" value={landOwner?.date_created} />
      </div>
    </DetailsCard>
  );
};
