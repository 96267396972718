import { Icon } from "@iconify/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { Avatar, Spinner, Table } from "../../components/shared";
import { Heading } from "../../components/shared/Heading";
import { SitePlan } from "../../custom-types";
import { useGetQuery } from "../../hooks/useGetQuery";
import { queryKeys } from "../../utils/query-keys";
import { SitePlanDetails } from "./SitePlanDetails";

export const AllSitePlans = () => {
  const [SitePlan, setSitePlan] = useState<SitePlan | null>(null);
  const { data, isLoading } = useGetQuery<{ message: SitePlan[] }>({
    queryKey: queryKeys.SitePlans.key,
    url: queryKeys.SitePlans.url,
  });

  console.log(data);

  const columnHelper = createColumnHelper<SitePlan>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    columnHelper.accessor(
      (row) =>
        `${row.owner_information.first_name} ${row.owner_information.last_name}`,
      {
        id: "Owner's Name",
        cell: (props) => (
          <div className="flex items-center">
            <div>
              {props.row.original.owner_information.profile_image ? (
                <img
                  src={props.row.original.owner_information.profile_image}
                  alt=""
                  className="h-12 w-12 object-cover rounded-full"
                />
              ) : (
                <Avatar size={40} />
              )}
            </div>
            <div className="ml-2">
              <div className=" text-blue-900 text-[15px] font-bold leading-snug">
                {props.row.original.owner_information.first_name}{" "}
                {props.row.original.owner_information.last_name}
              </div>
            </div>
          </div>
        ),
      }
    ),
    columnHelper.accessor((row) => `${row.land_information.plot_number}`, {
      header: "Plot Number",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor(
      (row) => `${row.owner_information.main_phone_number}`,
      {
        header: "Owner's Number",
        cell: (info) => <span>{formatPhoneNumberIntl(info.getValue())}</span>,
      }
    ),
    columnHelper.accessor((row) => `${row.land_information.locality}`, {
      header: "Plot Location",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("land_information.area", {
      header: "Total Land Area",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => `${row.land_information.region}`, {
      header: "Region",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.display({
      header: "Details",
      cell: (info) => (
        <button
          onClick={() => setSitePlan(info.row.original)}
          className="text-primary border border-primary hover:text-white hover:bg-primary duration-300 py-1 px-3 text-xs font-light rounded"
        >
          Details
        </button>
      ),
    }),
  ] as Array<ColumnDef<SitePlan, unknown>>;

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <div className="flex items-center justify-between">
        <Heading text="All Site Plans" />
        <Link
          to="/site-plans/add-site-plan"
          className="text-xs bg-primary py-2 rounded-md px-3 text-white font-normal flex items-center gap-2"
        >
          <Icon
            icon="material-symbols-light:add-circle-outline"
            fontSize={18}
          />
          New Site Plan
        </Link>
      </div>
      <Table columns={columns} data={data?.message ?? []} />

      <SitePlanDetails sitePlan={SitePlan} setSitePlan={setSitePlan} />
    </div>
  );
};
