import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/Login";
import { AllLandOwners } from "../../pages/land-owners/AllLandOwners";
import { AllPayments } from "../../pages/payments/AllPayments";
import { AllQRCodes } from "../../pages/qr-codes/AllQRCodes";
import { AllRoles } from "../../pages/roles/AllRoles";
import { AddSitePlan } from "../../pages/site-plans/AddSitePlan";
import { AllSitePlans } from "../../pages/site-plans/AllSitePlans";
import { EditSitePlan } from "../../pages/site-plans/EditSitePlan";
import { AddSurveyor } from "../../pages/surveyors/AddSurveyor";
import { AllSurveyors } from "../../pages/surveyors/AllSurveyors";
import EditSurveyor from "../../pages/surveyors/EditSurveyor";
import { AddUser } from "../../pages/users/AddUser";
import { AllUsers } from "../../pages/users/AllUsers";
import { Layout } from "../layout";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<AllSitePlans />} />

          {/* Site Plan */}
          <Route path="/site-plans">
            <Route index element={<AllSitePlans />} />
            <Route path="add-site-plan" element={<AddSitePlan />} />
            <Route path=":sitePlanId/edit" element={<EditSitePlan />} />
          </Route>

          {/* Land Owners */}
          <Route path="/land-owners">
            <Route index element={<AllLandOwners />} />
          </Route>

          {/* QR Codes */}
          <Route path="/qr-codes">
            <Route index element={<AllQRCodes />} />
          </Route>

          {/* Payment */}
          <Route path="/payments">
            <Route index element={<AllPayments />} />
          </Route>

          {/* Users */}
          <Route path="/users">
            <Route index element={<AllUsers />} />
            <Route path="add-user" element={<AddUser />} />
          </Route>

          {/* Roles */}
          <Route path="/roles" element={<AllRoles />} />

          {/* Surveyors */}
          <Route path="/surveyors">
            <Route index element={<AllSurveyors />} />
            <Route path="add-surveyor" element={<AddSurveyor />} />
            <Route path=":surveyorId/edit" element={<EditSurveyor />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
