import { Icon } from "@iconify/react";
import React, { useRef } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { DetailsCard, DetailsCardItem } from "../../components/shared";
import { QRCode } from "../../custom-types";
import { useReactToPrint } from "react-to-print";

type QRCodeDetailsProps = {
  qrCode: QRCode | null;
  setQrCode: React.Dispatch<React.SetStateAction<QRCode | null>>;
};

export const QRCodeDetails: React.FC<QRCodeDetailsProps> = ({
  qrCode = null,
  setQrCode,
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <DetailsCard
      heading="QRCode Details"
      title={`${qrCode?.site_plan.owner_information.first_name} ${qrCode?.site_plan.owner_information.last_name}`}
      description={`${qrCode?.site_plan.owner_information.email}`}
      closeDetails={() => setQrCode(null)}
      image={qrCode?.qrcode}
      openDetails={!!qrCode}
    >
      <div className="flex flex-col md:flex-row gap-5 p-5 rounded-md">
        <div
          ref={componentRef}
          className="group relative flex-1 bg-white rounded-md p-5 shrink-0 md:w-60 md:min-h-60 mx-auto overflow-hidden"
        >
          <img
            src={qrCode?.qrcode}
            alt="qr-code"
            className="rounded-md w-full"
          />
          <div className="hidden absolute inset-0 bg-primary/60 group-hover:flex justify-center items-center duration-300">
            <button
              onClick={handlePrint}
              className="bg-white py-2 px-8 rounded-full flex items-center gap-2"
            >
              <Icon icon="ph:printer-bold" />
              Print
            </button>
          </div>
        </div>
        <div className="flex-1 bg-white rounded-md p-5">
          <h3 className="font-bold mb-3">Owner's Information</h3>
          <div className="space-y-2 pl-2">
            <DetailsCardItem
              label="Full Name"
              value={`${qrCode?.site_plan.owner_information.first_name} ${qrCode?.site_plan.owner_information.middle_name} ${qrCode?.site_plan.owner_information.last_name}`}
            />
            <DetailsCardItem
              label="Email Address"
              value={qrCode?.site_plan.owner_information.email}
            />
            <DetailsCardItem
              label="Phone Number"
              value={formatPhoneNumberIntl(
                qrCode?.site_plan.owner_information.main_phone_number ?? ""
              )}
            />
            <DetailsCardItem
              label="Alternate Phone Number"
              value={formatPhoneNumberIntl(
                qrCode?.site_plan.owner_information.phone_numbers[0] ?? ""
              )}
            />
            <DetailsCardItem
              label="Postal Address"
              value={qrCode?.site_plan.owner_information.postal_address}
            />
            <DetailsCardItem
              label="GPS Address"
              value={qrCode?.site_plan.owner_information.gps_address}
            />
            <DetailsCardItem
              label="Identification Type"
              value={qrCode?.site_plan?.owner_information.identification_type}
            />
            <DetailsCardItem
              label="Identification Number"
              value={qrCode?.site_plan?.owner_information.identity_code}
            />
          </div>

          {/* Plot Information */}
          <h3 className="font-bold mt-5 mb-2">Plot Information</h3>
          <div className="space-y-2 pl-2">
            <DetailsCardItem
              label="Site Plan Hard Copy ID"
              value={qrCode?.site_plan.site_plan_hard_id}
            />
            <DetailsCardItem
              label="Locality"
              value={qrCode?.site_plan.land_information.locality}
            />
            <DetailsCardItem
              label="Municipality"
              value={qrCode?.site_plan.land_information.municipality}
            />
            <DetailsCardItem
              label="Region"
              value={qrCode?.site_plan.land_information.region}
            />
            <DetailsCardItem
              label="Block Number"
              value={qrCode?.site_plan.land_information.block_number}
            />
            <DetailsCardItem
              label="Sector"
              value={qrCode?.site_plan.land_information.sector}
            />
            <DetailsCardItem
              label="Plot Number"
              value={qrCode?.site_plan.land_information.plot_number}
            />
            <DetailsCardItem
              label="Total Land Area"
              value={qrCode?.site_plan.land_information.area}
            />
          </div>
        </div>
      </div>
    </DetailsCard>
  );
};
