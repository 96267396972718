import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Surveyor } from "../../custom-types";
import useMutate from "../../hooks/useMutation";
import { queryKeys } from "../../utils/query-keys";
import { FormRow, InputField, PhoneInput } from "../shared";
import { Heading } from "../shared/Heading";

const schema = (surveyor: Surveyor | undefined) => {
  let ghanaCard = /^GHA-\d{9}-\d$/i;

  const schema = z.object({
    first_name: z.string().min(1, "First name is required"),
    last_name: z.string().min(1, "Last name is required"),
    phone_number: z.string().length(13, "Please enter a valid phone number"),
    email: z.union([z.string().email(), z.null()]),
    profile_image: z.any(),
    ghana_card: z.any(),
    ghana_card_id: z.string().optional(),
  });

  if (!surveyor) {
    return schema
      .extend({
        password: z.string().min(6, "Password must be at least 6 characters"),
        confirm_password: z.string().min(6, "Please confirm your password"),
      })
      .refine((val) => val.password === val.confirm_password, {
        message: "Passwords do not match",
        path: ["confirm_password"],
      })
      .refine((val) => val.ghana_card_id && ghanaCard.test(val.ghana_card_id), {
        message: "Please enter a valid Ghana Card ID",
        path: ["ghana_card_id"],
      });
  }

  return schema.refine(
    (val) => val.ghana_card_id && ghanaCard.test(val.ghana_card_id),
    {
      message: "Please enter a valid Ghana Card ID",
      path: ["ghana_card_id"],
    }
  );
};

type SurveyorFormProps = {
  surveyor?: Surveyor;
};

export const SurveyorForm: React.FC<SurveyorFormProps> = ({ surveyor }) => {
  const queryClient = useQueryClient();
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [ghanaCardImage, setGhanaCardImage] = useState<File | null>(null);
  const [profileImagePreview, setProfileImagePreview] = useState<string | null>(
    null
  );
  const [ghanaCardImagePreview, setGhanaCardImagePreview] = useState<
    string | null
  >(null);
  const formValues = schema(surveyor);
  type FormValues = z.infer<typeof formValues>;
  const methods = useForm<FormValues>({
    defaultValues: {
      first_name: surveyor?.first_name || "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      confirm_password: "",
      profile_image: "",
      ghana_card: "",
      ghana_card_id: "",
    },
    resolver: zodResolver(schema(surveyor)),
  });

  const navigate = useNavigate();
  const { mutate } = useMutate();
  const submit: SubmitHandler<FormValues> = (data) => {
    const toastId = toast.loading("Adding Surveyor...");
    const formData = new FormData();
    Object.entries(data).forEach((entry) => {
      formData.append(...entry);
    });

    mutate(
      {
        url: surveyor ? `/surveyor/${surveyor?._id}` : "/surveyor/new",
        method: surveyor ? "PATCH" : "POST",
        data: formData,
      },
      {
        onSuccess(data) {
          console.log({ data });
          queryClient.setQueryData<{ message: Surveyor[] }>(
            queryKeys.Surveyors.key,
            (oldData) => {
              if (surveyor) {
                return {
                  message: (oldData?.message ?? []).map((item) => {
                    if (item._id === surveyor._id) {
                      return data.message;
                    }
                    return item;
                  }),
                };
              } else {
                return {
                  message: [data.message, ...(oldData?.message ?? [])],
                };
              }
            }
          );
          toast.dismiss(toastId);
          navigate("/surveyors");
        },
        onError(error: any) {
          console.log({ error });
          toast.dismiss(toastId);
          console.log({ error });
        },
      }
    );
  };

  useEffect(() => {
    if (surveyor) {
      const { setValue } = methods;
      setValue("first_name", surveyor.first_name);
      setValue("last_name", surveyor.last_name);
      setValue("phone_number", surveyor.phone_number);
      setValue("email", surveyor.email);
      setValue("ghana_card_id", surveyor.ghana_card_id);
      setValue("profile_image", surveyor.profile_image);
      setValue("ghana_card", surveyor.ghana_card);

      setProfileImagePreview(surveyor.profile_image);
      setGhanaCardImagePreview(surveyor.ghana_card);
    }
  }, [surveyor, methods]);

  useEffect(() => {
    if (profileImage) {
      methods.setValue("profile_image", profileImage);
      setProfileImagePreview(URL.createObjectURL(profileImage));
    }
  }, [profileImage, methods]);

  useEffect(() => {
    if (ghanaCardImage) {
      methods.setValue("ghana_card", ghanaCardImage);
      setGhanaCardImagePreview(URL.createObjectURL(ghanaCardImage));
    }
  }, [ghanaCardImage, methods]);

  console.log(methods.formState.errors);

  return (
    <div>
      <Heading text="Add Surveyor" />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <FormRow>
            <InputField name="first_name" label="First Name" />
            <InputField name="last_name" label="Last Name" />
          </FormRow>
          <FormRow>
            <PhoneInput name="phone_number" label="Phone Number" />
            <InputField name="email" label="Email" type="email" />
          </FormRow>
          {!surveyor ? (
            <FormRow>
              <InputField name="password" type="password" label="Password" />
              <InputField
                name="confirm_password"
                type="password"
                label="Confirm Password"
              />
            </FormRow>
          ) : null}
          <FormRow>
            <InputField name="ghana_card_id" label="Ghana Card ID" />
          </FormRow>
          <div className="form-row">
            <div className="flex-1">
              {profileImagePreview ? (
                <div className="flex gap-5 flex-wrap justify-center py-3">
                  <div className="relative h-32 w-[200px] shrink-0 rounded-md bg-slate-500">
                    <div className="overflow-hidden">
                      <img
                        src={profileImagePreview}
                        style={{ objectFit: "cover" }}
                        alt=""
                        className="w-full h-32 rounded object-contain"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="w-full md:w-8/12 mx-auto">
                <label className="mb-1 block text-blue-900 text-md font-semibold leading-loose">
                  Ghana Card
                </label>
                <label
                  htmlFor="dropzone-file-1"
                  className={`flex-1 w-full flex items-center justify-center  border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Icon
                      icon="line-md:cloud-upload-outline-loop"
                      className="w-10 h-10 text-gray-400"
                    />
                    <div className="text-center text-sm">
                      <span className="text-blue-900 font-bold">
                        Drop your Ghana Card picture here, or
                      </span>
                      <span className="text-red-500 font-bold ml-2">
                        browse
                      </span>
                    </div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG and GIF files are allowed
                    </p>
                  </div>
                  <input
                    id="dropzone-file-1"
                    type="file"
                    className="hidden"
                    onChange={(e) =>
                      setProfileImage(e.target.files && e.target.files[0])
                    }
                  />
                </label>
              </div>
            </div>
            <div className="flex-1">
              {ghanaCardImagePreview ? (
                <div className="flex gap-5 flex-wrap justify-center py-3">
                  <div className="relative h-32 w-32 shrink-0 rounded-md bg-slate-500">
                    <div className="overflow-hidden">
                      <img
                        src={ghanaCardImagePreview}
                        style={{ objectFit: "cover" }}
                        alt=""
                        className="w-32 h-32 rounded object-cover"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="w-full md:w-8/12 mx-auto">
                <label className="mb-1 block text-blue-900 text-md font-semibold leading-loose">
                  Profile Image
                </label>
                <label
                  htmlFor="dropzone-file"
                  className={`flex-1 w-full flex items-center justify-center  border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Icon
                      icon="line-md:cloud-upload-outline-loop"
                      className="w-10 h-10 text-gray-400"
                    />
                    <div className="text-center text-sm">
                      <span className="text-blue-900 font-bold">
                        Drop your profile picture here, or
                      </span>
                      <span className="text-red-500 font-bold ml-2">
                        browse
                      </span>
                    </div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG and GIF files are allowed
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) =>
                      setGhanaCardImage(e.target.files && e.target.files[0])
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button className="bg-primary text-white py-2 px-10 rounded-md float-right">
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
