import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Avatar, Spinner, Table } from "../../components/shared";
import { Heading } from "../../components/shared/Heading";
import { Surveyor } from "../../custom-types";
import useConfirm from "../../hooks/useConfirm";
import { useGetQuery } from "../../hooks/useGetQuery";
import useMutate from "../../hooks/useMutation";
import { queryKeys } from "../../utils/query-keys";

export const AllSurveyors = () => {
  const queryClient = useQueryClient();
  const { ConfirmationDialog, confirm, setIsOpen } = useConfirm();
  const { data, isLoading } = useGetQuery<{ message: Surveyor[] }>({
    queryKey: queryKeys.Surveyors.key,
    url: queryKeys.Surveyors.url,
  });

  const { mutate } = useMutate(["delete-surveyor"]);
  const handleDelete = async (surveyor: Surveyor) => {
    const isConfirmed = await confirm({
      title: "Are you sure?",
      message: `Are you sure you want to delete "${surveyor?.first_name} ${surveyor?.last_name}"?`,
    });

    if (isConfirmed) {
      const toastId = toast.loading(
        `Deleting ${surveyor?.first_name} ${surveyor?.last_name}...`
      );

      mutate(
        {
          url: `/surveyor/${surveyor?._id}`,
          method: "DELETE",
        },
        {
          onSuccess(data) {
            queryClient.setQueryData<{ message: Surveyor[] }>(
              queryKeys.Surveyors.key,
              (oldData) => {
                console.log({ queryClient, oldData });
                return {
                  message: (oldData?.message ?? []).filter(
                    (item) => item._id !== surveyor?._id
                  ),
                };
              }
            );
            toast.dismiss(toastId);
            toast.success(
              `${surveyor?.first_name} ${surveyor?.last_name} deleted successfully`
            );
            setIsOpen(false);
          },
          onError(error: any) {
            toast.dismiss(toastId);
            toast.error(error.response.data.message);
          },
        }
      );
    }
  };

  const columnHelper = createColumnHelper<Surveyor>();
  const columns = [
    columnHelper.display({
      id: "name",
      header: "No.",
      cell: (info) => <span className="pl-2">{info.row.index + 1}</span>,
    }),
    columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
      id: "Owner's Name",
      cell: (props) => (
        <div className="flex items-center">
          <div>
            {props.row.original.profile_image ? (
              <img
                src={props.row.original.profile_image}
                alt=""
                className="h-12 w-12 object-cover rounded-full"
              />
            ) : (
              <Avatar size={40} />
            )}
          </div>
          <div className="ml-2">
            <div className=" text-blue-900 text-[15px] font-bold leading-snug">
              {props.row.original.first_name} {props.row.original.last_name}
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("phone_number", {
      header: "Phone Number",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("ghana_card_id", {
      header: "Ghana Card ID",
      cell: (info) => info.getValue(),
    }),
    {
      header: "Actions",
      cell: (info) => (
        <div className="w-[%] flex gap-3">
          <Link to={`/surveyors/${info.row.original._id}/edit`}>
            <Icon icon="iconamoon:edit-light" className="text-xl" />
          </Link>
          <Icon
            onClick={() => handleDelete(info.row.original)}
            icon="fluent:delete-48-regular"
            className="text-xl text-red-700"
          />
        </div>
      ),
    },
  ] as Array<ColumnDef<Surveyor, unknown>>;

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <div className="flex justify-between">
        <Heading text="All Surveyors" />
        <Link
          to="/surveyors/add-surveyor"
          className="text-xs bg-primary py-2 rounded-md px-3 text-white font-normal flex items-center gap-2"
        >
          <Icon
            icon="material-symbols-light:add-circle-outline"
            fontSize={18}
          />
          New Surveyor
        </Link>
      </div>

      <Table data={data?.message} columns={columns} />

      <ConfirmationDialog />
    </div>
  );
};
