import React from "react";
import { useSidebar } from "../../hooks/useSidebar";

type SpinnerProps = {
  isLoading: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({ isLoading }) => {
  const isOpen = useSidebar();

  if (isLoading) {
    return (
      <div
        className={`fixed top-0 right-0 bottom-0 ${
          isOpen ? "left-80" : "left-20"
        } bg-black opacity-60 grid place-content-center`}
      >
        <span className="loader"></span>
      </div>
    );
  } else {
    return null;
  }
};
